<template>
  <h4>Home</h4>
</template>

<script>

export default {
  name: 'Home',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.isAdmin || vm.isGrafico) {
        next('/progetti')
      } else {
        next('/rendicontazione')
      }
    })
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    isGrafico() {
      return this.$store.getters.isGrafico
    }
  }
}
</script>

<template>
  <div>
  <div class="project-detail mb-2" v-if="isAdmin && projectFilterEnabled">
    <h5>Rendicontazione progetto:  {{project ? project.code: ''}} - {{project ? project.name: ''}}</h5><b-button @click="cleanFilter" size="sm">Pulisci</b-button>
  </div>
  <div>
    <b-form-group label="Data">
      <b-form-datepicker
            id="i-data"
            v-model="dateFilter"
            placeholder="Filtra per giorno"
            @input="filter"
            locale="it-IT"
          >
          </b-form-datepicker>
        </b-form-group>
          <b-form-group
            label="Dipendente"
            v-if="isAdmin"
          >
            <b-select
              :options="employees"
              v-model="employeeFilter"
              text-field="name"
              value-field="id"
              @change="filter"
            >
              <template #first>
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
            </b-select>
        </b-form-group>
        <b-button @click="clear" v-if="isAdmin">Pulisci</b-button>
</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import rendicontazioneService from '@/services/rendicontazioneService'
import projectService from '@/services/projectService'

export default {
  created() {
    if (!this.isAdmin) {
      this.dateFilter = new Date()
      const today = this.serializeDate(this.dateFilter)
      const filter = { date: today }
      this.getRendicontazioni(filter)
      this.setRendicontazioneFilter(filter)
    } else {
      this.employeeFilter = this.rendicontazioneFilter.employee
      this.dateFilter = this.rendicontazioneFilter.date
      this.getRendicontazioni(this.rendicontazioneFilter)

      rendicontazioneService.fetchUsersData()
        .then(data => { this.employees = data })
    }
    if (this.projectFilterEnabled) {
      projectService.fetchProject(this.rendicontazioneFilter.projectId)
        .then(data => { this.project = data })
    }
  },
  data() {
    return {
      project: null,
      employeeFilter: null,
      dateFilter: null,
      employees: null
    }
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'rendicontazioneFilter'
    ]),
    projectFilterEnabled() {
      return this.rendicontazioneFilter.projectId !== null && this.rendicontazioneFilter.projectId !== undefined
    }
  },
  methods: {
    filter() {
      this.setRendicontazioneFilter({ date: this.dateFilter, employee: this.employeeFilter })
      this.getRendicontazioni(this.rendicontazioneFilter)
    },
    clear() {
      this.dateFilter = null
      this.employeeFilter = null
      this.cleanFormRendicontazioneFilter()
      this.filter()
    },
    cleanFilter() {
      this.cleanProjectRendicontazioneFilter()
      this.getRendicontazioni(this.rendicontazioneFilter)
    },
    serializeDate(date) {
      const y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      if (m < 10) {
        m = '0' + m
      }
      if (d < 10) {
        d = '0' + d
      }
      return `${y}-${m}-${d}`
    },
    ...mapActions([
      'cleanProjectRendicontazioneFilter',
      'cleanFormRendicontazioneFilter',
      'setRendicontazioneFilter',
      'getRendicontazioni'
    ])
  }
}
</script>

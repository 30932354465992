<template>
  <b-row class="agenda-header">
    <b-col>
      <div class="text-center"><b-button @click="previous" size="sm">&lt;</b-button></div>
    </b-col>
    <b-col>
      <b-row>
        <b-col><h2 class="text-center">{{date | fdate}}</h2></b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="text-center"><b-button @click="today" size="sm">oggi</b-button></div>
        </b-col>
        <b-col cols="6">
          <div class="text-center"><b-button to="/interventi" size="sm" title="vai a calendario"><b-icon icon="calendar"/></b-button></div>
        </b-col>
  </b-row>
    </b-col>
    <b-col>
      <div class="text-center"><b-button @click="next" size="sm">&gt;</b-button></div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  created() {
    this.$emit('date', this.date)
  },
  data() {
    return {
      date: this.noTime(new Date())
    }
  },
  methods: {
    noTime(d) {
      return new Date(d.getFullYear(), d.getMonth(), d.getDate())
    },
    previous() {
      const y = this.date.getFullYear()
      const m = this.date.getMonth()
      const d = this.date.getDate()
      this.date = new Date(y, m, d - 1)
      this.$emit('date', this.date)
    },
    next() {
      const y = this.date.getFullYear()
      const m = this.date.getMonth()
      const d = this.date.getDate()
      this.date = new Date(y, m, d + 1)
      this.$emit('date', this.date)
    },
    today() {
      this.date = this.noTime(new Date())
      this.$emit('date', this.date)
    }
  }
}
</script>

<style>
.agenda-header {
  background-color: #f0f0f0;
}
</style>

export default class User {
  constructor() {
    this.id = null
    this.username = null
    this.name = null
    this.surname = null
    this.role = null
    this.isCapofficina = false
    this.enabled = null
    this.useInExportRendicontazione = null
    this.workRole = null
    this.weeklyWorkingHours = null
  }
}

<template>
<b-navbar toggleable="md" variant="dark" type="dark" sticky>
   <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="profile !== null">
        <b-nav-item to="/progetti">Progetti</b-nav-item>
        <b-nav-item to="/rendicontazione">Rendicontazione</b-nav-item>
        <b-nav-item to="/interventi">Interventi</b-nav-item>
        <b-nav-item-dropdown text="Amministrazione" v-if="isAdmin">
          <b-dropdown-item to="/gestione-utenti">Gestione utenti</b-dropdown-item>
          <b-dropdown-item to="/amministrazione">Configurazione</b-dropdown-item>
          <b-dropdown-item to="/statistiche">Statistiche Progetti</b-dropdown-item>
          <b-dropdown-item to="/statistiche-dipendenti">Statistiche Dipendenti</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <b-nav-text right v-if="profile !== null">
      Ciao {{profile.username}} <b-button type="link" @click="logout">esci</b-button>
    </b-nav-text>
</b-navbar>
</template>

<script>
import userService from '@/services/userService'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'profile', 'isAdmin'
    ])
  },
  methods: {
    logout() {
      userService.logout()
    }
  }
}
</script>

<template>
  <b-row class="mt-5">
    <b-col>
      <b-button to="/gestione-utenti/utente/">Crea</b-button>
      <user-search class="mb-2"/>
        <b-alert
        v-if="showMsgs"
        variant="success"
        fade
        :show="2"
        @dismissed="cleanMsgs"
      >
        {{msg}}
      </b-alert>
      <user-table />
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters } from 'vuex';
import UserSearch from '../components/usermanagement/UserSearch.vue';
import UserTable from '../components/usermanagement/UserTable.vue';

export default {
  components: { UserTable, UserSearch },
  computed: {
    showMsgs() {
      return this.msg.length > 0
    },
    ...mapGetters([
      'msg'
    ])
  },
  methods: {
    cleanMsgs() {
      this.$store.dispatch('setMsg', '')
    }
  }
};
</script>

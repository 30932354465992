export default class Interventation {
  constructor() {
    this.time = '08:00'
    this.project = {
      code: '',
      name: ''
    }
    this.type = null
  }
}

import axios from '@/services/httpService';

const MAX_CUSTOMERS_PER_PAGE = 5;

const fetchCustomersByName = (name) => {
  const config = {
    params: {
      name,
      page: 0,
      size: MAX_CUSTOMERS_PER_PAGE
    }
  }
  return axios.get('/api/customer', config)
    .then(res => res.data.content)
    .catch(err => console.log(err))
}

const edit = (customer) => {
  return axios.put(`/api/customer/${customer.id}`, customer)
    .then(res => res.data)
    .catch(err => console.log(err))
}
const save = (customer) => {
  return axios.post('/api/customer', customer)
    .then(res => res.data)
    .catch(err => console.log(err))
}

const fetchCustomer = (customerId) => {
  return axios.get(`/api/customer/${customerId}`)
    .then(res => res.data)
    .catch(err => console.log(err))
}

const fetchLocationByCity = (city) => {
  const config = {
    params: {
      city: city
    }
  }
  return axios.get('/api/customer/location', config)
    .then(res => res.data)
    .catch(err => {
      if (err.response.status === 404) {
        return { zipCode: '', province: '' }
      } else {
        console.log(err)
      }
    })
}

export default {
  fetchCustomersByName,
  fetchCustomer,
  fetchLocationByCity,
  edit,
  save
}

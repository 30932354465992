import Vue from 'vue'
import Vuex from 'vuex'
import rendicontazioneService from '@/services/rendicontazioneService';
import RendicontazioneFilter from '@/models/RendicontazioneFilter'
import UserManagementFilter from '../models/UserManagementFilter';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // rendicontazione
    rendicontazioni: [],
    loadedRendicontazione: { state: 'create', content: { date: new Date(), employee: {}, project: { code: '', name: '' }, activity: {}, hours: 0, minutes: 0 }, index: -1 },
    rendicontazioneFilter: new RendicontazioneFilter(),

    // projects
    filter: { year: null, state: null, value: null, modes: ['code', 'name', 'description', 'location'] },

    // user management
    userManagementFilter: new UserManagementFilter(),

    // autenticazione
    accessToken: window.localStorage.getItem('accessToken'),
    profile: JSON.parse(window.localStorage.getItem('profile')),

    // general
    msg: '',
    error: ''
  },
  mutations: {
    // rendicontazione
    newRendicontazione(state, rendicontazione) { // maybe not more useful, because when I create a new one a read the list from api
      state.rendicontazioni.unshift(rendicontazione);
    },
    editRendicontazione(state, { index, content }) {
      state.rendicontazioni.splice(index, 1, content);
    },
    loadedRendicontazione(state, rendicontazioneState) {
      state.loadedRendicontazione = rendicontazioneState;
    },
    unloadRendicontazione(state) {
      state.loadedRendicontazione = { state: 'create', content: { date: new Date(), employee: {}, project: { code: '', name: '' }, activity: {}, hours: 0, minutes: 0 }, index: -1 };
    },
    deleteRendicontazione(state, { index }) {
      state.rendicontazioni.splice(index, 1);
    },
    setRendicontazioni(state, rendicontazioni) {
      state.rendicontazioni = rendicontazioni;
    },
    setRendicontazioneFilter(state, filter) {
      state.rendicontazioneFilter = Object.assign(new RendicontazioneFilter(), state.rendicontazioneFilter, filter)
    },
    cleanRendicontazioneFilter(state, cleanProj) {
      let newFilter
      if (cleanProj) {
        const { date, employee } = state.rendicontazioneFilter
        newFilter = { date, employee }
      } else {
        const { projectId } = state.rendicontazioneFilter
        newFilter = { projectId }
      }
      state.rendicontazioneFilter = Object.assign(new RendicontazioneFilter(), newFilter)
    },

    // projects
    setFilter(state, filter) {
      state.filter = filter;
    },
    // authentication
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    resetAccessToken(state) {
      state.accessToken = null;
    },
    resetProfile(state) {
      state.profile = null;
    },

    // user management
    setUserManagementFilter(state, filter) {
      state.userManagementFilter = Object.assign(new UserManagementFilter(), state.userManagementFilter, filter)
    },
    cleanUserManagementFilter(state) {
      state.userManagementFilter = new UserManagementFilter();
    },
    cleanError(state) {
      state.error = ''
    },

    // general
    setMsg(state, msg) {
      state.msg = msg
    },
    setError(state, msg) {
      state.error = msg
    }
  },
  actions: {
    // rendicontazione
    saveRendicontazione(ctx, rendicontazione) {
      rendicontazioneService.saveRendicontazione(rendicontazione)
        .then(dataRes => { ctx.dispatch('getRendicontazioni', ctx.getters.rendicontazioneFilter) });
    },
    editRendicontazione(ctx, { index, content }) {
      rendicontazioneService.editRendicontazione(content)
        .then(dataRes => { ctx.dispatch('getRendicontazioni', ctx.getters.rendicontazioneFilter) })
    },
    loadRendicontazione(ctx, { index, id }) {
      const rendicontazione = Object.assign({}, this.getters.rendicontazioni[index]);
      if (!rendicontazione.project) {
        rendicontazione.project = {};
      }
      ctx.commit('loadedRendicontazione', { state: 'edit', content: rendicontazione, index: index });
    },
    unloadRendicontazione(ctx) {
      ctx.commit('unloadRendicontazione');
    },
    deleteRendicontazione(ctx, { index, id }) {
      rendicontazioneService.deleteRendicontazione(id)
        .then(res => { ctx.commit('deleteRendicontazione', { index }) });
    },
    getRendicontazioni(ctx, filter) {
      rendicontazioneService.fetchRendicontazioni(filter)
        .then(data => { ctx.commit('setRendicontazioni', data) });
    },
    getRendicontazioniByProject(ctx, projectId) {
      rendicontazioneService.fetchRendicontazioniByProject(projectId)
        .then(data => { ctx.commit('setRendicontazioni', data) });
    },
    setRendicontazioneFilter(ctx, filter) {
      ctx.commit('setRendicontazioneFilter', filter);
    },
    cleanProjectRendicontazioneFilter({ commit }) {
      commit('cleanRendicontazioneFilter', true)
    },
    cleanFormRendicontazioneFilter({ commit }) {
      commit('cleanRendicontazioneFilter', false)
    },

    // projects
    setFilter(ctx, filter) {
      ctx.commit('setFilter', filter);
    },
    // autenticazione
    authenticateUser(ctx, { accessToken, profile }) {
      ctx.commit('setAccessToken', accessToken);
      ctx.commit('setProfile', profile);
    },
    logout(ctx) {
      ctx.commit('resetAccessToken');
      ctx.commit('resetProfile');
    },

    // user management
    setUserManagementFilter(ctx, filter) {
      ctx.commit('setUserManagementFilter', filter);
    },
    cleanUserManagementFilter({ commit }) {
      commit('cleanUserManagementFilter', true)
    },

    // general
    setMsg(ctx, msg) {
      ctx.commit('setMsg', msg)
    },
    setError(ctx, msg) {
      ctx.commit('setError', msg)
    },
    cleanError(ctx) {
      ctx.commit('cleanError')
    }
  },
  getters: {
    // rendicontazione
    rendicontazioni(state) {
      return state.rendicontazioni
    },
    loadedRendicontazione(state) {
      return state.loadedRendicontazione
    },
    rendicontazioneFilter(state) {
      return state.rendicontazioneFilter
    },

    // projects
    filter(state) {
      return state.filter
    },

    // autenticazione
    profile(state) {
      return state.profile
    },
    accessToken(state) {
      return state.accessToken
    },
    isAuthenticated(state) {
      return state.profile !== null
    },
    isAdmin(state, getters) {
      return getters.isAuthenticated && getters.profile.authorities.filter(a => a.authority === 'ROLE_ADMIN').length !== 0
    },
    isGrafico(state, getters) {
      return getters.isAuthenticated && getters.profile.authorities.length === 1 && getters.profile.authorities[0].authority === 'ROLE_GRAFICO'
    },
    isTecnico(state, getters) {
      return getters.isAuthenticated && !getters.isAdmin && getters.profile.authorities.filter(a => a.authority === 'ROLE_TECNICO').length !== 0
    },
    isCapofficina(state, getters) {
      return getters.isAuthenticated && !getters.isAdmin && getters.profile.authorities.filter(a => a.authority === 'ROLE_CAPOFFICINA').length !== 0
    },

    // user management
    userManagementFilter(state) {
      return state.userManagementFilter
    },

    // general
    msg(state) {
      return state.msg
    },
    error(state) {
      return state.error
    }
  },
  modules: {
  }
})

import axios from '@/services/httpService'
import store from '@/store';
import router from '@/router';

const registration = (user) => {
  const registeredUsers = JSON.parse(window.localStorage.getItem('users')) || [];
  if (registeredUsers.filter(u => u.nickname === user.nickname).length === 0) {
    registeredUsers.push(user);
    window.localStorage.setItem('users', JSON.stringify(registeredUsers));
  }
};

const authenticate = (user) => {
  return axios.post(
    '/auth/login',
    {
      username: user.nickname,
      password: user.password
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ).then(resp => {
    const accessToken = resp.data.access_token;
    return axios.get(
      '/api/user',
      {
        headers: {
          Authorization: 'Bearer ' + accessToken
        }
      }
    ).then(resp => {
      const profile = resp.data;
      store.dispatch('authenticateUser', { accessToken, profile });
      window.localStorage.setItem('accessToken', accessToken);
      window.localStorage.setItem('profile', JSON.stringify(profile));
      return true;
    })
  }).catch(err => {
    if (err.response) { // response sent
      if (err.response.status === 401) {
        console.log('autenticazione fallita');
      }
    } else { // response not sent
      console.log('problemi comunicazione server');
    }
    return false;
  });
};

const isAuthenticated = () => {
  return store.getters.isAuthenticated;
};

const logout = () => {
  store.dispatch('logout');
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('profile');
  router.push('/login');
};

const userService = {
  registration,
  authenticate,
  isAuthenticated,
  logout
};

export default userService;

<template>
  <div>
    <b-row class="mt-5">
      <b-col>
        <b-button @click="create" v-show="!showForm">Nuova</b-button>
        <b-button @click="exportSelection" v-show="!showForm" v-if="isAdmin" class="ml-2">Esporta</b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <RendicontazioneFilter v-if="!showForm"/>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <RendicontazioneForm v-if="showForm" @submit="hide" @reset="hide" />
        <RendicontazioneTable @edit="showForm = true" v-show="!showForm" v-if="isDesktop" />
        <RendicontazioneListGroup @edit="showForm = true" v-show="!showForm" v-if="!isDesktop"  />
      </b-col>
    </b-row>

    <b-modal
    id="delete-confirm"
    ok-title="Conferma"
    ok-variant="danger"
    cancel-title="Annulla"
    no-close-on-backdrop
    hide-header-close
    @ok="remove"
    @cancel="cancel"
  >
   <p>Sicuro di voler eliminare ?</p>
  </b-modal>

  <b-modal
  id="export-selection"
  :hide-footer="true"
  >
   <b-form-row>
          <b-col sm="6">
            <b-form-group label="Mese">
              <b-form-select
                id="i-month"
                :options="months"
                v-model="exportMonth"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Anno">
              <b-form-select
                id="i-year"
                :options="years"
                v-model="exportYear"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-button @click="exportRendicontazione">Esporta</b-button>
  </b-modal>
  </div>

</template>

<script>
import RendicontazioneForm from '@/components/RendicontazioneForm'
import RendicontazioneTable from '@/components/RendicontazioneTable'
import RendicontazioneListGroup from '@/components/RendicontazioneListGroup'
import RendicontazioneFilter from '@/components/RendicontazioneFilter'

import rendicontazioneService from '@/services/rendicontazioneService'
import { mapGetters } from 'vuex';
export default {
  created() {
    if (this.isAdmin) {
      // popolute export selection modal
      this.months = [
        { text: 'Gennaio', value: 1 },
        { text: 'Febbraio', value: 2 },
        { text: 'Marzo', value: 3 },
        { text: 'Aprile', value: 4 },
        { text: 'Maggio', value: 5 },
        { text: 'Giugno', value: 6 },
        { text: 'Luglio', value: 7 },
        { text: 'Agosto', value: 8 },
        { text: 'Settembre', value: 9 },
        { text: 'Ottobre', value: 10 },
        { text: 'Novembre', value: 11 },
        { text: 'Dicembre', value: 12 }
      ]
      this.years = this.generateYearOptions()
    }
  },
  data() {
    return {
      showForm: false,
      isDesktop: document.documentElement.clientWidth >= 768,
      exportMonth: new Date().getMonth() + 1,
      exportYear: new Date().getFullYear(),
      months: null,
      years: null
    };
  },
  methods: {
    create() {
      this.showForm = true;
    },
    hide() {
      this.showForm = false;
    },
    remove() {
      const toRemove = this.$store.getters.loadedRendicontazione;
      this.$store.dispatch('deleteRendicontazione', { index: toRemove.index, id: toRemove.content.id });
      this.$store.dispatch('unloadRendicontazione');
      this.hide();
    },
    cancel() {
      this.$store.dispatch('unloadRendicontazione');
      this.hide();
    },
    generateYearOptions() {
      const olderYear = 2007;
      const currentYear = new Date().getFullYear();
      const years = Array.from({ length: (currentYear - olderYear) / 1 + 1 }, (_, i) => currentYear - i);
      return years.map(y => ({ text: y, value: y }))
    },
    exportSelection() {
      this.$bvModal.show('export-selection')
    },
    exportRendicontazione() {
      rendicontazioneService.exportRendicontazione(this.exportMonth, this.exportYear)
      this.$bvModal.hide('export-selection')
    }
  },
  computed: {
    ...mapGetters([
      'isGrafico',
      'isTecnico',
      'isAdmin'
    ])
  },
  components: {
    RendicontazioneForm,
    RendicontazioneTable,
    RendicontazioneListGroup,
    RendicontazioneFilter
  }
}
</script>

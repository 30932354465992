<template>
  <div class="calendar-container">
    <calendar-view
      :show-date="showDate"
      :show-times="true"
      :time-format-options="{ hour: '2-digit', minute: '2-digit'}"
      locale="it-IT"
      current-period-label="Oggi"
      class="theme-default"
      display-period-uom="week"
      :display-period-count="4"
      :starting-day-of-week="1"
      @click-date="onDateClick"
      @click-item="onItemClick"
      @drop-on-date="onDrop"
      :items="interventations"
      :enable-drag-drop="true"
      :periodChangedCallback="fetchInterventations"
    >
      <template #header="{ headerProps }">
          <calendar-view-header-agenda
          :header-props="headerProps"
          @input="setShowDate">
        </calendar-view-header-agenda>
      </template>
  </calendar-view>
  <Interventation modal-id="interventation-detail" :date-selected="dateSelected" :item-selected="interventationSelected" @saved="refresh" @removed="removed"/>
  </div>
</template>

<script>
import { CalendarView } from 'vue-simple-calendar'
import CalendarViewHeaderAgenda from '@/components/intervention/CalendarViewHeaderAgenda'
// The next two lines are optional themes
import 'vue-simple-calendar/static/css/default.css'
import 'vue-simple-calendar/static/css/holidays-us.css'

import Interventation from '@/components/Interventation'
import interventationSrv from '@/services/interventationService'
import InterventationModel from '@/models/Interventation'

export default {
  beforeRouteEnter(to, from, next) {
    const isDesktop = document.documentElement.clientWidth >= 768
    if (isDesktop || from.path === '/interventi/agenda') {
      next()
    } else {
      next('/interventi/agenda')
    }
  },
  data: function() {
    return {
      dateSelected: null,
      interventationSelected: new InterventationModel(),
      showDate: new Date(),
      interventations: []
    }
  },
  components: {
    CalendarView,
    CalendarViewHeaderAgenda,
    Interventation
  },
  methods: {
    setShowDate(d) {
      this.showDate = d;
    },
    onDateClick(...params) {
      if (!this.$store.getters.isTecnico) {
        this.dateSelected = params[0]
        this.interventationSelected = new InterventationModel()
        this.$bvModal.show('interventation-detail')
      }
    },
    onItemClick(item) {
      this.interventationSelected = item.originalItem
      this.$bvModal.show('interventation-detail')
    },
    onDrop(item, date) {
      if (!this.$store.getters.isTecnico) {
        interventationSrv.edit(date, item.originalItem)
          .then(data => {
            const idx = this.interventations.findIndex(i => i.id === data.id)
            this.interventations.splice(idx, 1, data)
          })
      }
    },
    fetchInterventations({ displayFirstDate, displayLastDate }) {
      interventationSrv.fetchInterventations(displayFirstDate, displayLastDate)
        .then(data => { this.interventations = data })
    },
    refresh(interventation, removed = false) {
      const idx = this.interventations.findIndex(i => i.id === interventation.id)
      if (idx >= 0) {
        if (removed) {
          this.interventations.splice(idx, 1)
        } else {
          this.interventations.splice(idx, 1, interventation)
        }
      } else {
        this.interventations.push(interventation)
      }
    },
    removed(interventation) {
      this.refresh(interventation, true)
    }
  }
}
</script>

<style>
.calendar-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: #2c3e50;
  height: 90vh;
}

.nextYear, .previousYear {
  display: none
}
</style>

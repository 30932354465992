<template>
  <div>
    <b-button-group>
      <b-button :href="`http://www.google.com/maps/search/${mapsUrl}`" target="_blank" size="sm" :disabled="disableMap" title="Vedi in mappa">
        <b-icon icon="geo-alt-fill"></b-icon>
      </b-button>
      <b-button size="sm" @click="copyToClip" :disabled="disableCustomerDataCopy" title="Copia dati cliente">
        <b-icon icon="clipboard"></b-icon>
      </b-button>
    </b-button-group>
    <b-form>
      <b-form-group
        label="Nome"
      >
      <b-input
        id="i-cust-name"
        v-model.trim="customer.name"
        :disabled="readOnly"
      >
      </b-input>
      </b-form-group>
      <b-form-group
        label="Indirizzo"
        :disabled="readOnly"
      >
      <b-input
        id="i-cust-address"
        v-model.trim="customer.address"
      >
      </b-input>
      </b-form-group>
      <b-row>
        <b-col md="9">
          <b-form-group label="Città">
            <b-input id="i-cust-city" v-model.trim="customer.city" :disabled="readOnly" @input="searchForLocation" />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Cap">
            <b-input id="i-cust-cap" v-model.trim="customer.cap" :disabled="readOnly"/>
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-form-group label="Prov.">
            <b-input id="i-cust-province" v-model.trim="customer.province" :disabled="readOnly"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group label="P.Iva">
            <b-input id="i-cust-piva" v-model.trim="customer.vatNumber" :disabled="readOnly"/>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Cod.Fisc.">
            <b-input id="i-cust-fiscalCode" v-model.trim="customer.fiscalCode" :disabled="readOnly"/>
          </b-form-group>
        </b-col>
      </b-row>
      <CustomerContact class="mt-1" :contact="customer.contact1" header="Contatto 1" :disabled="readOnly" @updateContact="updateContact1"/>
      <CustomerContact class="mt-1" :contact="customer.contact2" header="Contatto 2" :disabled="readOnly" @updateContact="updateContact2"/>
      <CustomerContact class="mt-1" :contact="customer.contact3" header="Contatto 3" :disabled="readOnly" @updateContact="updateContact3"/>
      <div class="mt-2" v-if="!isTecnico">
        <b-button @click="cancel" class="mr-2">Annulla</b-button>
        <b-button @click="edit" v-show="readOnly">Modifica</b-button>
        <b-button @click="save" v-show="!readOnly" :disabled="!validated">Salva</b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import customerService from '@/services/customerService'
import CustomerContact from '@/components/project/CustomerContact'
import Customer from '@/models/Customer'

export default {
  props: {
    selectedCustomer: Object
  },
  data() {
    return {
      customer: new Customer(),
      editState: true,
      action: 'create',
      oldCustomer: {}
    }
  },
  watch: {
    selectedCustomer: {
      handler: function(value) {
        if (value != null) {
          this.customer = Object.assign({}, value)
          this.editState = false
          this.action = 'edit'
        } else {
          this.customer = new Customer()
          this.editState = true
          this.action = 'create'
        }
      }
    }
  },
  computed: {
    readOnly() {
      return this.editState === false
    },
    validated() {
      return this.customer.name && this.customer.name.length > 0
    },
    isTecnico() {
      return this.$store.getters.isTecnico
    },
    mapsUrl() {
      const search = `${this.customer.address}, ${this.customer.cap} ${this.customer.city} ${this.customer.province}, Italia`
      return encodeURIComponent(search).replace(/%20/g, '+');
    },
    disableMap() {
      return this.customer.address === undefined || this.customer.address === null || this.customer.address.length === 0
    },
    disableCustomerDataCopy() {
      return (this.customer.address === undefined || this.customer.address === null || this.customer.address.length === 0) ||
      (this.customer.name === undefined || this.customer.name === null || this.customer.name.length === 0)
    }
  },
  methods: {
    cancel() {
      if (this.editState && this.action === 'edit') {
        this.editState = false
        this.restoreCustomer()
        this.$emit('upsertCustomer', false)
      } else {
        this.customer = new Customer()
        this.$emit('cancel')
      }
    },
    edit() {
      this.editState = true
      this.oldCustomer = Object.assign({}, this.customer)
      this.$emit('upsertCustomer', true)
    },
    save() {
      if (this.action === 'edit') {
        customerService.edit(this.customer)
          .then(data => {
            this.$emit('selectedCustomer', data.id)
            this.editState = false
          })
      } else {
        customerService.save(this.customer)
          .then(data => {
            this.$emit('selectedCustomer', data.id)
            this.editState = false
          })
      }
    },
    restoreCustomer() {
      Object.assign(this.customer, this.oldCustomer)
    },
    searchForLocation() {
      customerService.fetchLocationByCity(this.customer.city)
        .then(data => {
          this.customer.cap = data.zipCode
          this.customer.province = data.province
        })
    },
    copyToClip() {
      const customerData = `${this.customer.name} ${this.customer.address} ${this.customer.cap ? this.customer.cap : ''} ${this.customer.city ? this.customer.city : ''} ${this.customer.province ? this.customer.province : ''}`
      this.$clipboard(customerData)
    },
    updateContact1(contact) {
      this.customer.contact1 = contact
    },
    updateContact2(contact) {
      this.customer.contact2 = contact
    },
    updateContact3(contact) {
      this.customer.contact3 = contact
    }
  },
  components: {
    CustomerContact
  }
}
</script>

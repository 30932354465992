<template>
  <div>
    <div v-for="item in items"  :key="item.year">
      <h3>{{item.year}}</h3>
      <b-button @click="download(item.year)" size="sm" class="mb-2">Scarica</b-button>
      <b-table bordered hover :items="item.userStats" :fields="fields"></b-table>
    </div>
  </div>
</template>

<script>
import rendicontazioneSrv from '@/services/rendicontazioneService'
export default {
  created() {
    rendicontazioneSrv.statistics()
      .then(data => { this.items = data })
  },
  data() {
    return {
      fields: [
        {
          key: 'fullname',
          label: 'Dipendente'
        },
        {
          key: 'total',
          label: 'Totale',
          class: 'stronger'
        },
        {
          key: 'userActivityStats[0]',
          label: 'Costruzione',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[1]',
          label: 'Struttura',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[2]',
          label: 'Verniciatura',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[3]',
          label: 'Fresa',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[4]',
          label: 'Soffiera',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[5]',
          label: 'Led',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[6]',
          label: 'Decorazione',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[7]',
          label: 'Posa in opera',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[8]',
          label: 'Carico/Scarico Furgone',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[9]',
          label: 'Riparazione',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[10]',
          label: 'Viaggio',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[11]',
          label: 'Rilievo',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[12]',
          label: 'Ferie/Permesso',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[13]',
          label: 'Malattia',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[14]',
          label: 'Pulizia',
          formatter: value => value.hours
        },
        {
          key: 'userActivityStats[15]',
          label: 'Varie',
          formatter: value => value.hours
        }
      ],
      items: []
    }
  },
  methods: {
    download(year) {
      const csv = this.items.filter(item => item.year === year).map(stats => this.toCsv(stats.userStats))
      const url = window.URL.createObjectURL(new Blob([csv]))
      const link = document.createElement('a')
      link.href = url
      const fileName = `rendicontazioni-dipendenti-${year}.csv`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    },
    toCsv(userStats) {
      const rowCsv = userStats => {
        const hours = userStats.userActivityStats.map(uas => uas.hours).join(',')
        return `${userStats.fullname},${userStats.total},${hours}\n`
      }
      const headerCsv = 'Dipendente,Totale,Costruzione,Struttura,Verniciatura,Fresa,Soffiera,Led,Decorazione,Posa in opera,Carico/Scarico Furgone,Riparazione,Viaggio,Rilievo,Ferie/Permesso,Malattia,Pulizia,Varie\n'
      const rows = ''.concat(...userStats.map(userStat => rowCsv(userStat)))
      return headerCsv.concat(rows)
    }
  }
}
</script>

<style>
.stronger {
  font-weight: bold;
}
</style>

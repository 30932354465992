import axios from '@/services/httpService';

const fetchProjects = (ctx = { currentPage: 1, perPage: 5 }, filter) => {
  const config = {
    params: {
      page: ctx.currentPage - 1,
      size: ctx.perPage,
      state: filter.state,
      year: filter.year,
      modes: filter.modes ? filter.modes.join(',') : null,
      value: filter.value
    }
  }
  return axios.get('/api/project', config)
    .then(res => res.data)
    .catch(err => console.log(err))
};

const fetchProjectsData = (filter = {}) => {
  const config = {
    params: {
      year: filter.year,
      state: filter.state
    }
  }
  return axios.get('/api/project/data', config)
    .then(res => res.data)
    .catch(err => console.log(err));
};

const fetchStates = () => {
  return axios.get('/api/project/state')
    .then(res => res.data)
    .catch(err => console.log(err))
}

const save = (project) => {
  return axios.post('/api/project', project)
    .then(res => res.data)
    .catch(err => console.log(err))
}

const edit = (project) => {
  return axios.put(`/api/project/${project.id}`, project)
    .then(res => res.data)
    .catch(err => console.log(err))
}

const fetchProject = (projectId) => {
  return axios.get(`/api/project/${projectId}`)
    .then(res => {
      const d = res.data
      return {
        id: d.id,
        name: d.name,
        code: d.code,
        requestDate: d.requestDate,
        state: d.state ? d.state.id : null,
        source: d.source ? d.source.id : null,
        description: d.description,
        location: d.location,
        billingCustomer: d.billingCustomer ? d.billingCustomer.id : null,
        destinationCustomer: d.destinationCustomer ? d.destinationCustomer.id : null,
        ladderTruck: d.ladderTruck,
        fixing: d.fixing,
        examination1: d.examination1,
        examination2: d.examination2,
        implementation: d.implementation,
        interventations: d.interventations
      }
    })
    .catch(err => console.log(err))
};

const fetchSources = () => {
  return axios.get('/api/project/source')
    .then(res => res.data)
    .catch(err => console.log(err))
}

const fetchModels = (isFixingProject) => {
  if (isFixingProject) {
    return [
      { id: 3, name: 'rilievo riparazione' },
      { id: 4, name: 'scheda lavoro riparazione' }
    ]
  } else {
    return [
      { id: 1, name: 'rilievo' },
      { id: 2, name: 'scheda lavoro' }
    ]
  }
}

const generateDoc = (project, model) => {
  return axios.get(`/api/project/${project.id}/model/${model.id}`, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = `${project.code} ${model.name}_${project.name}.docx`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    })
    .then(() => {
      // if model is rilievo move project state to Disegno
      if (model.id === 1 && project.state.id === projectStates.rilievo) {
        return _upgradeState(project.id, projectStates.disegno)
      }
      // if model is scheda-lavoro or scheda-lavoro-riparazione move project state to InLavorazione
      if (model.id === 2 || model.id === 4) {
        return _upgradeState(project.id, projectStates.inLavorazione)
      }
    })
}

const exportProjects = () => {
  return axios.get('/api/project/export', { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = 'export-projects.csv'
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    })
}

const exportDb = () => {
  return axios.get('/api/download/export-db', { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = 'neontrento-db.tgz'
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    })
}

const statistics = () => {
  return axios.get('/api/statistics/distributions')
    .then(res => res.data)
    .catch(err => { console.log(err) })
}

const _upgradeState = (projectId, stateId) => {
  return axios.put(`/api/project/${projectId}/state/${stateId}`)
    .then(res => res.data)
    .catch(err => { console.log(err) })
}

const projectService = {
  fetchProjects,
  fetchProjectsData,
  fetchProject,
  fetchStates,
  fetchSources,
  fetchModels,
  generateDoc,
  save,
  edit,
  exportProjects,
  exportDb,
  statistics
}

const projectStates = {
  rilievo: 1,
  disegno: 2,
  inLavorazione: 6,
  lavoroProntoDaPosare: 9,
  varie: 16 // not correct: this is an activity for Rendicontazione non a project state
}

export { projectService as default, projectStates }

<template>
  <b-modal
    :id="modalId"
    :ok-disabled="isInvalid"
    ok-title="Salva"
    cancel-title="Annulla"
    @ok="save"
    @cancel="cancel"
    :no-close-on-backdrop="true"
  >
    <b-form-group
      label="Tipo" :disabled="readOnly || mode === 'edit'">
      <b-select v-model="interventation.type">
        <b-select-option :value="null"></b-select-option>
        <b-select-option value="interventation">Intervento</b-select-option>
        <b-select-option value="reminder">Promemoria</b-select-option>
      </b-select>
    </b-form-group>

    <b-form-group
      label="Orario"
      v-show="isInterventation"
    >
    <b-timepicker
      v-model="interventation.time"
      locale="it-IT"
      :hide-header="true"
      :reset-button="true"
      label-reset-button="Pulisci"
      label-close-button="Chiudi"
      label-no-time-selected="Nessun orario"
      :disabled="readOnly"
    >
    </b-timepicker>
    </b-form-group>
    <ProjectNameSearch :project.sync="interventation.project" :disabled="readOnly" v-show="isInterventation" />
    <b-form-group
      label="Note"
      v-show="interventation.type !== null"
    >
    <b-textarea :disabled="readOnly"
      v-model="interventation.notes"
    >
    </b-textarea>
    </b-form-group>
    <span v-show="isInterventation && interventation.project.fixing" class="fixing tag mr-1">riparazione</span>
    <span v-show="isInterventation && interventation.project.ladderTruck" class="tag">autoscala</span>

    <template #modal-footer>
      <div v-show="!readOnly && !isTecnico">
        <b-button @click="cancel" class="mr-2">Annulla</b-button>
        <b-button @click="save" :disabled="isInvalid">Salva</b-button>
      </div>
      <div v-show="readOnly && !isTecnico">
        <b-button variant="danger" @click="remove" class="mr-2">
          <b-icon icon="trash" />
        </b-button>
        <b-button @click="edit">Modifica</b-button>
      </div>
      <div v-if="readOnly && isInterventation">
        <b-button :to="'/progetto/' + interventation.project.id">
          Vai a progetto
        </b-button>
      </div>
      <div v-if="readOnly && isInterventation">
        <shared-folder-link :project="interventation.project" :is-folder-def="true" type="button" />
      </div>
    </template>
  </b-modal>
</template>

<script>
import ProjectNameSearch from '@/components/project/ProjectNameSearch'
import SharedFolderLink from '@/components/project/SharedFolderLink'
import interventationSrv from '@/services/interventationService'
import Interventation from '@/models/Interventation'

export default {
  props: {
    modalId: String,
    dateSelected: Date,
    itemSelected: Object
  },
  watch: {
    itemSelected: {
      handler: function(value) {
        this.readOnly = value !== null && (value.id !== null && value.id !== undefined)
        this.interventation = value !== null ? Object.assign({}, value) : { time: '08:00' }
      }
    }
  },
  data() {
    return {
      interventation: new Interventation(),
      readOnly: false,
      mode: 'save'
    }
  },
  computed: {
    isInvalid() {
      return (this.interventation.type === 'interventation' && this.interventation.project.code.length === 0) ||
        this.interventation.type === null
    },
    isInterventation() {
      return this.interventation.type === 'interventation'
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    isTecnico() {
      return this.$store.getters.isTecnico
    }
  },
  methods: {
    save() {
      if (this.mode === 'save') {
        interventationSrv.save(this.dateSelected, this.interventation)
          .then(data => {
            this.$emit('saved', data)
            this.interventation = new Interventation()
            this.$bvModal.hide(this.modalId)
          })
      } else {
        interventationSrv.edit(null, this.interventation)
          .then(data => {
            this.mode = 'save'
            this.$emit('saved', data)
            this.$bvModal.hide(this.modalId)
          })
      }
    },
    cancel() {
      this.mode = 'save'
      this.interventation = new Interventation()
      this.$bvModal.hide(this.modalId)
    },
    edit() {
      this.readOnly = false
      this.mode = 'edit'
    },
    remove() {
      this.$bvModal.msgBoxConfirm('Sicuro di voler eliminare l\'intervento?', {
        okVariant: 'danger',
        okTitle: 'Sì',
        cancelTitle: 'No',
        hideHeaderClose: false
      })
        .then(result => {
          if (result) {
            interventationSrv.remove(this.interventation)
              .then(data => {
                this.$emit('removed', data)
                this.$bvModal.hide(this.modalId)
              })
          }
        })
    }
  },
  components: {
    ProjectNameSearch,
    SharedFolderLink
  }
}
</script>

<style>
.tag {
  background-color: #1de6c9;
  border-radius: 5px;
  padding: 4px;
  font-size: 13px;
}

.reminder {
  background-color: #3de17d !important;
}

.tag.fixing, .fixing {
  background-color: #fabf8f !important;
}
</style>

<template>
  <b-row class="mt-5">
    <b-col>
     <b-button to="/progetto/" v-if="isAdmin || isGrafico">Crea</b-button>
     <ProjectsSearch class="mb-2"/>
     <ProjectsTable />
    </b-col>
  </b-row>
</template>

<script>
import ProjectsSearch from '@/components/project/ProjectsSearch'
import ProjectsTable from '@/components/project/ProjectsTable'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'isAdmin',
      'isGrafico'
    ])
  },
  components: {
    ProjectsSearch,
    ProjectsTable
  }
}
</script>

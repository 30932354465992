<template>
    <b-row class="mt-2">
      <b-col md="6">
      <b-form-group
        label="Cerca"
        label-cols-sm="1"
        label-align-sm="left"
      >
        <b-form-input
          class="mr-2"
          v-model="filter.value"
          @input="refreshFilter"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="1"
        label-align-sm="left"
      >
        <b-form-checkbox-group
        v-model="filter.modes"
        :options="searchModes"
        @change="refreshFilter"
     >
      </b-form-checkbox-group>
      </b-form-group>
      <b-row>
        <b-col md="6">
           <b-form-group
            label="Anno"
            label-cols-sm="2"
            label-align-sm="left"
            >
              <b-form-select
                :options="yearOptions"
                v-model="filter.year"
                @change="refreshFilter"
              >
                <template #first>
                 <b-form-select-option :value="null"></b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
        </b-col>
        <b-col md="6">
           <b-form-group
            label="Stato"
            label-cols-sm="2"
            label-align-sm="left"
         >
              <b-form-select
                :options="stateOptions"
                v-model="filter.state"
                text-field="name"
                value-field="id"
                @change="refreshFilter"
              >
               <template #first v-if="!isOnlyTecnico">
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
      <b-button @click="clear">Pulisci</b-button>
        </b-col>
      </b-row>
      </b-col>
    </b-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import projectService, { projectStates } from '@/services/projectService'

export default {
  created() {
    projectService.fetchStates()
      .then(states => {
        if (this.isOnlyTecnico) {
          this.stateOptions = states.filter(s => s.id === projectStates.inLavorazione || s.id === projectStates.lavoroProntoDaPosare)
        } else {
          this.stateOptions = states
        }
      })
    this.yearOptions = this.generateYearOptions();
    if (this.isOnlyTecnico) {
      this.filter.state = projectStates.inLavorazione
      this.refreshFilter()
    }
    // read filter from vuex
    this.filter = this.getFilter
  },
  data() {
    return {
      filter: {
        year: null,
        state: null,
        value: null,
        modes: ['code', 'name', 'description', 'location']
      },
      searchModes: [
        { value: 'name', text: 'Nome' },
        { value: 'location', text: 'Luogo' },
        { value: 'description', text: 'Descrizione' }
      ],
      yearOptions: [],
      stateOptions: []
    }
  },
  computed: {
    isOnlyTecnico() {
      return this.isTecnico && !this.isCapofficina
    },
    ...mapGetters({
      getFilter: 'filter',
      isTecnico: 'isTecnico',
      isCapofficina: 'isCapofficina'
    })
  },
  methods: {
    generateYearOptions() {
      const olderYear = 2007;
      const currentYear = new Date().getFullYear();
      const years = Array.from({ length: (currentYear - olderYear) / 1 + 1 }, (_, i) => currentYear - i);
      return years.map(y => ({ text: y, value: y }))
    },
    refreshFilter() {
      this.setFilter(Object.assign({}, this.filter))
    },
    clear() {
      this.filter = {
        year: null,
        state: null,
        value: null,
        modes: ['code', 'name', 'description', 'location']
      }
      this.refreshFilter()
    },
    ...mapActions([
      'setFilter'
    ])
  }
}
</script>

import axios from 'axios';
import store from '@/store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT
});

instance.interceptors.request.use(config => {
  const authToken = store.getters.accessToken;
  if (authToken) {
    config.headers.Authorization = 'Bearer ' + authToken;
  }
  return config;
})

instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  console.log(`dispatcher ${error}`)
  if (error.response.status === 401) {
    console.log('Ciao sono dentro')
    store.dispatch(
      'setError',
      'Problemi di autenticazione, fai logout dal sistema ed effettua il login nuovamente.'
    )
  }

  // return Promise.reject(error);
});
export default instance;

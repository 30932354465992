<template>
  <div>
    <b-form-group label="Progetto">
      <b-input-group>
        <b-input v-model.trim="projectName" @input="searchProject" :disabled="disabled"></b-input>
          <b-button @click="cleanField" title="Pulisci" :disabled="disabled">
            <b-icon icon="x"></b-icon>
          </b-button>
        </b-input-group>
      </b-form-group>
      <b-list-group v-show="foundProjects">
        <b-list-group-item v-for="p in projects" :key="p.id" @click="selectProject(p)">{{fullName(p)}}</b-list-group-item>
      </b-list-group>
      <div v-show="!foundProjects && searching">
        <span>Nessun risultato</span>
      </div>
  </div>
</template>

<script>
import projectService, { projectStates } from '@/services/projectService'

export default {
  props: {
    disabled: Boolean,
    project: Object,
    byState: Number,
    isNormalUser: Boolean
  },
  data() {
    return {
      projectName: this.fullName(this.project),
      projects: [],
      searching: false
    }
  },
  computed: {
    foundProjects() {
      return this.projects.length > 0
    }
  },
  methods: {
    searchProject() {
      this.searching = true
      const ctx = {
        currentePage: 1,
        perPage: 5
      }
      const filter = {
        modes: ['name', 'code'],
        value: this.projectName,
        state: this.byState
      }
      projectService.fetchProjects(ctx, filter)
        .then(data => {
          if (this.isNormalUser) {
            const isACode = new RegExp('^\\d\\d-').test(this.projectName)
            if (isACode) { // search for all project states
              this.projects = data.content
            } else { // search only for inLavorazione or lavoroProntoDaPosare
              this.projects = data.content.filter(p => p.state.id === projectStates.inLavorazione || p.state.id === projectStates.lavoroProntoDaPosare)
            }
          } else {
            this.projects = data.content
          }
        })
    },
    cleanField() {
      this.projectName = ''
      this.projects = []
      this.searching = false
      this.$emit('update:project', { code: '', name: '' })
    },
    selectProject(project) {
      this.projectName = this.fullName(project)
      this.projects = []
      this.searching = false
      this.$emit('update:project', project)
    },
    fullName(project) {
      if (project && project.code && project.code.length > 0) {
        return `${project.code} - ${project.name ? project.name : 'Progetto senza nome'}`
      } else {
        return ''
      }
    }
  }
}
</script>

<template>

  <div>
    <div class="project-detail mt-2">
      <p>Scarica il file in formato csv di tutti i progetti presenti nel sistema</p>
      <b-button @click="downloadExportProjects">Scarica</b-button>
    </div>
     <div class="project-detail mt-2">
      <p>Scarica una copia backup dell'intero database</p>
      <b-button @click="downloadExportDb">Scarica</b-button>
    </div>
  </div>
</template>
<script>
import projectService from '@/services/projectService'

export default {
  methods: {
    downloadExportProjects() {
      projectService.exportProjects()
    },
    downloadExportDb() {
      projectService.exportDb()
    }
  }
}
</script>

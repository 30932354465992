import CustomerContact from '@/models/CustomerContact'

export default class Customer {
  constructor() {
    this.zipCode = ''
    this.province = ''
    this.contact1 = new CustomerContact()
    this.contact2 = new CustomerContact()
    this.contact3 = new CustomerContact()
  }
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Clipboard from 'v-clipboard'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// copy to clipboard plugin (v-clipboard)
Vue.use(Clipboard)

Vue.filter('fdate', function(value) {
  const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
  return new Date(value).toLocaleString('it-IT', options);
})

Vue.filter('fdatetime', function(value) {
  const options = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  return new Date(value).toLocaleString('it-IT', options);
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div>
  <b-table
    ref="usersTable"
    responsive
    striped
    :fields="fields"
    :items="dataProvider"
    :per-page="perPage"
    :current-page="currentPage"
  >
   <template #cell(enabled)="row">
      <b-icon icon="check-circle" style="color: green" title="sì" v-if="row.item.enabled"/>
      <b-icon icon="x-circle" style="color: red" title="no" v-else/>
   </template>
   <template #cell(useInExportRendicontazione)="row">
      <b-icon icon="check-circle" style="color: green" title="sì" v-if="row.item.useInExportRendicontazione"/>
      <b-icon icon="x-circle" style="color: red" title="no" v-else/>
   </template>
   <template #cell(actions)="row">
      <b-button size="sm" :to="`/gestione-utenti/utente/${row.item.id}`" title="Modifica" class="mr-2">
        <b-icon icon="brush"></b-icon>
      </b-button>
    </template>
  </b-table>
  <b-pagination
    v-model="currentPage"
    :total-rows="rows"
    :per-page="perPage"
  ></b-pagination>
  </div>
</template>

<script>
import userManagementService from '@/services/userManagementService'

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      rows: 0,
      fields: [
        {
          key: 'username',
          label: 'Username'
        },
        {
          key: 'name',
          label: 'Nome'
        },
        {
          key: 'surname',
          label: 'Cognome'
        },
        {
          key: 'enabled',
          label: 'Attivo'
        },
        {
          key: 'useInExportRendicontazione',
          label: 'Mostra in rendicontazione'
        },
        {
          key: 'authorities',
          label: 'Ruoli',
          formatter: value => value.map(v => v.authority).map(v => v.substring(5)).join(', ')
        },
        {
          key: 'actions',
          label: 'Azioni'
        }
      ],
      projects: [],
      project: {},
      models: [],
      model: null
    }
  },
  computed: {
    filter() {
      return this.$store.getters.userManagementFilter
    }
  },
  watch: {
    filter: {
      handler: function() {
        this.$refs.usersTable.refresh();
      }
    }
  },
  methods: {
    dataProvider(ctx) {
      return userManagementService.fetchUsers(ctx, this.filter)
        .then(page => {
          this.rows = page.totalElements;
          return page.content;
        });
    }
  }
}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginForm from '@/components/LoginForm.vue'
import Rendicontazione from '@/views/Rendicontazione.vue'
import Projects from '@/views/Projects'
import Project from '@/views/Project'
import Home from '@/views/Home'
import Interventions from '@/views/Interventions'
import AgendaInterventions from '@/views/AgendaInterventions'
import Administration from '@/views/Administration'
import Statistics from '@/views/Statistics'
import StatisticsEmployees from '@/views/StatisticsEmployees'
import UserManagement from '@/views/UserManagement'
import User from '@/views/User'
import ChangePassword from '@/views/ChangePassword'

import userService from '@/services/userService'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginForm,
    meta: {
      protected: false
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      protected: true
    }
  },
  {
    path: '/rendicontazione',
    name: 'Rendicontazione',
    component: Rendicontazione,
    meta: {
      protected: true
    }
  },
  {
    path: '/progetti',
    name: 'Projects',
    component: Projects,
    meta: {
      protected: true
    }
  },
  {
    path: '/progetto',
    name: 'ProjectNew',
    component: Project,
    meta: {
      protected: true
    }
  },
  {
    path: '/progetto/:id',
    name: 'ProjectEdit',
    component: Project,
    meta: {
      protected: true
    }
  },
  {
    path: '/interventi',
    name: 'Intervention',
    component: Interventions,
    meta: {
      protected: true
    }
  },
  {
    path: '/interventi/agenda',
    name: 'AgendaIntervention',
    component: AgendaInterventions,
    meta: {
      protected: true
    }
  },
  {
    path: '/gestione-utenti',
    name: 'UserManagement',
    component: UserManagement,
    meta: {
      protected: true
    }
  },
  {
    path: '/gestione-utenti/utente/:id',
    name: 'User',
    component: User,
    meta: {
      protected: true
    }
  },
  {
    path: '/gestione-utenti/utente',
    name: 'NewUser',
    component: User,
    meta: {
      protected: true
    }
  },
  {
    path: '/gestione-utenti/utente/:id/cambia-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      protected: true
    }
  },
  {
    path: '/amministrazione',
    name: 'Administration',
    component: Administration,
    meta: {
      protected: true
    }
  },
  {
    path: '/statistiche',
    name: 'Statistics',
    component: Statistics,
    meta: {
      protected: true
    }
  },
  {
    path: '/statistiche-dipendenti',
    name: 'StatisticsEmployee',
    component: StatisticsEmployees,
    meta: {
      protected: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login' && store.getters.isAuthenticated) {
    next('/');
  } else if (to.matched.some(record => record.meta.protected)) {
    if (!userService.isAuthenticated()) {
      next('/login')
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router

<template>
  <b-alert :show="showErrors" variant="danger">
    {{error}}
  </b-alert>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['error']),
    showErrors() {
      return this.error.length > 0
    }
  }
}
</script>

<template>
  <div>
    <b-row v-show="!showForm">
      <b-col>
        <b-form-group label="Cliente">
          <b-input-group>
            <b-input id="i-customer" v-model.trim="customerName" @input="searchCustomer"></b-input>
            <b-button @click="cleanField" title="Pulisci">
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-input-group>
        </b-form-group>
        <b-list-group v-show="foundCustomers">
          <b-list-group-item v-for="c in customers" :key="c.id" @click="selectCustomer(c)">{{c.name}}</b-list-group-item>
        </b-list-group>
        <b-row v-show="!foundCustomers">
          <b-col>
            <span>Nessun risultato</span> <b-button @click="createCustomer">Nuovo</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-show="showForm">
      <b-col>
        <CustomerForm @cancel="clean" @selectedCustomer="selectedCustomerId" @upsertCustomer="upsert" :selected-customer="selectedCustomer"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CustomerForm from '@/components/project/CustomerForm'
import customerService from '@/services/customerService'

export default {
  props: {
    customerId: Number
  },
  data() {
    return {
      customerName: '',
      customers: [],
      showForm: false,
      selectedCustomer: null
    }
  },
  watch: {
    customerId: {
      handler: function(value) {
        if (value !== null) {
          customerService.fetchCustomer(value)
            .then(data => {
              this.showForm = true
              this.selectedCustomer = data
            })
        }
      }
    }
  },
  computed: {
    foundCustomers() {
      return this.customers.length > 0;
    }
  },
  methods: {
    searchCustomer() {
      if (this.customerName.length > 0) {
        customerService.fetchCustomersByName(this.customerName)
          .then(data => { this.customers = data })
      } else {
        this.customers = []
      }
    },
    selectCustomer(customer) {
      this.$emit('selectedCustomer', customer.id)
      customerService.fetchCustomer(customer.id)
        .then(data => {
          this.selectedCustomer = data
          this.showForm = true
        })
    },
    selectedCustomerId(customerId) {
      this.$emit('selectedCustomer', customerId)
    },
    createCustomer() {
      this.showForm = true
      this.selectedCustomer = null
      this.$emit('upsertCustomer', true)
    },
    clean() {
      this.showForm = false
      this.selectedCustomer = null
      this.$emit('upsertCustomer', false)
    },
    cleanField() {
      this.customerName = ''
      this.customers = []
    },
    upsert(value) {
      this.$emit('upsertCustomer', value)
    }
  },
  components: {
    CustomerForm
  }
}
</script>

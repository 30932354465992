<template>
  <b-table bordered hover :items="items" :fields="fields"></b-table>
</template>

<script>
import projectSrv from '@/services/projectService'
export default {
  created() {
    projectSrv.statistics()
      .then(data => { this.items = data })
  },
  data() {
    return {
      fields: [
        {
          key: 'year',
          label: 'Anno'
        },
        {
          key: 'total',
          label: 'Totale'
        },
        {
          key: 'distributions[0]',
          label: 'Rilievo',
          formatter: this.percentage
        },
        {
          key: 'distributions[1]',
          label: 'Disegno',
          formatter: this.percentage
        },
        {
          key: 'distributions[2]',
          label: 'Preventivo',
          formatter: this.percentage
        },
        {
          key: 'distributions[3]',
          label: 'Attesa Decisione Cliente',
          formatter: this.percentage
        },
        {
          key: 'distributions[4]',
          label: 'Domande Comunali',
          formatter: this.percentage
        },
        {
          key: 'distributions[5]',
          label: 'In Realizzazione',
          formatter: this.percentage
        },
        {
          key: 'distributions[6]',
          label: 'Lavoro Pronto Da Posare',
          formatter: this.percentage
        },
        {
          key: 'distributions[7]',
          label: 'Lavoro Completato',
          formatter: this.percentage
        },
        {
          key: 'distributions[8]',
          label: 'Lavoro Non Accettato',
          formatter: this.percentage
        }
      ],
      items: []
    }
  },
  methods: {
    percentage(value) {
      return `${(Math.round(value.value * 100) / 100).toFixed(1)}%`
    }
  }
}
</script>

<template>
  <div>
    <b-row class="mt-5" v-if="isEditAction">
      <b-col>
        <div class="project-detail">
          <h5>Nome: {{project.name}}</h5>
          <h5>Codice: <shared-folder-link :project="project"/></h5>
          <h5>Data Richiesta: {{project.requestDate | fdate}}</h5>
          <h5 v-if="project.interventations.length !== 0">Calendario interventi:</h5>
          <ul v-if="project.interventations.length !== 0">
            <li v-for="interventation in project.interventations" :key="interventation.id">
              {{interventation.startDate | fdatetime}}
            </li>
          </ul>
          <b-button @click="goToRendicontazione(project)" size="sm" title="Vai a rendicontazione" v-if="isAdmin">
            Rendicontazione
        </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col md="6">
        <div class="project-detail">
        <h6 class="header">Destinazione</h6>
        <b-checkbox @change="checkDestinationCustomer"
          v-model="disableDestinationCustomer"
        >
          Destinazione stessa della fatturazione
        </b-checkbox>
        <div v-show="!disableDestinationCustomer">
          <Customer @selectedCustomer="selectedDestinationCustomer" @upsertCustomer="upsertDestinationState" :customerId="project.destinationCustomer" />
        </div>
        </div>
      </b-col>
      <b-col md="6">
        <div class="project-detail">
        <h6 class="header">Fatturazione</h6>
        <Customer @selectedCustomer="selectedBillingCustomer" @upsertCustomer="upsertBillingState" :customerId="project.billingCustomer" />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form>
          <b-form-group label="Stato">
          <b-select
          :options="states"
          text-field="name"
          value-field="id"
          v-model="project.state"
          >
          </b-select>
          </b-form-group>
          <b-form-group label="Richiesta Cliente">
            <b-textarea v-model="project.description"></b-textarea>
          </b-form-group>
          <b-form-group label="Autoscala">
            <b-checkbox v-model="project.ladderTruck"></b-checkbox>
          </b-form-group>
          <b-form-group label="Riparazione">
            <b-checkbox v-model="project.fixing"></b-checkbox>
          </b-form-group>
          <b-form-group label="Diagnosi 1" v-show="project.fixing">
            <b-textarea v-model="project.examination1"></b-textarea>
          </b-form-group>
          <b-form-group label="Diagnosi 2" v-show="project.fixing">
            <b-textarea v-model="project.examination2"></b-textarea>
          </b-form-group>
          <b-form-group label="Da eseguire" v-show="project.fixing">
            <b-textarea v-model="project.implementation"></b-textarea>
          </b-form-group>
          <b-form-group label="Come ha conosciuto la Neon Trento">
             <b-select
              :options="sources"
              text-field="name"
              value-field="id"
              v-model="project.source"
          >
            <template #first>
              <b-form-select-option :value="null"></b-form-select-option>
            </template>
          </b-select>
          </b-form-group>
        <div v-if="!isTecnico">
          <b-button class="mr-2" to="/progetti">Annulla</b-button>
          <b-button @click="save" :disabled="disableSave">Salva</b-button>
        </div>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Customer from '@/components/project/Customer'
import projectService, { projectStates } from '@/services/projectService'
import SharedFolderLink from '@/components/project/SharedFolderLink'
import { mapActions } from 'vuex'

export default {
  created() {
    projectService.fetchStates()
      .then(data => { this.states = data })
    projectService.fetchSources()
      .then(data => { this.sources = data })
    const projectId = this.$route.params.id
    if (projectId) {
      projectService.fetchProject(projectId)
        .then(data => {
          this.project = data
          this.disableDestinationCustomer = this.project.destinationCustomer === null
        })
    }
  },
  watch: {
    project: {
      handler(newValue, oldValue) {
        if (newValue.fixing && (newValue.implementation == null || newValue.implementation === undefined)) {
          this.project.implementation = 'Urgente - Al passaggio'
        }
      },
      deep: true
    }
  },
  data() {
    return {
      states: [],
      sources: [],
      project: { state: projectStates.rilievo, interventations: [] },
      disableDestinationCustomer: true,
      upsertBillingCustomer: false,
      upsertDestinationCustomer: false
    }
  },
  computed: {
    isEditAction() {
      return this.$route.params.id !== undefined
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    isGrafico() {
      return this.$store.getters.isGrafico
    },
    isTecnico() {
      return this.$store.getters.isTecnico
    },
    disableSave() {
      return this.upsertBillingCustomer || this.upsertDestinationCustomer
    }
  },
  methods: {
    ...mapActions([
      'setRendicontazioneFilter'
    ]),
    save() {
      if (this.isEditAction) {
        projectService.edit(this.project)
          .then(data => { this.$router.push('/progetti') })
      } else {
        projectService.save(this.project)
          .then(data => { this.$router.push('/progetti') })
      }
    },
    selectedBillingCustomer(customerId) {
      this.project.billingCustomer = customerId
      this.upsertBillingCustomer = false
    },
    selectedDestinationCustomer(customerId) {
      this.project.destinationCustomer = customerId
      this.upsertDestinationCustomer = false
    },
    checkDestinationCustomer(value) {
      if (value) {
        this.project.destinationCustomer = null
      }
    },
    upsertBillingState(upsert) {
      this.upsertBillingCustomer = upsert
    },
    upsertDestinationState(upsert) {
      this.upsertDestinationCustomer = upsert
    },
    goToRendicontazione(project) {
      this.setRendicontazioneFilter({ projectId: project.id })
      this.$router.push('/rendicontazione')
    }
  },
  components: {
    Customer,
    SharedFolderLink
  }
}
</script>

<style>
.project-detail {
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #c1d0d0;
}

.project-detail .header {
  background-color: #c1d0d0;
  padding: 5px;
  border-radius: 5px;
}
</style>

import axios from '@/services/httpService'

const save = (date, interventation) => {
  const time = interventation.time
  const startDate = date
  if (time && time.length > 0 && interventation.type === 'interventation') {
    const hours = parseInt(time.substring(0, 2))
    const minutes = parseInt(time.substring(3))
    startDate.setHours(hours, minutes)
  }
  const payload = {
    id: interventation.id,
    notes: interventation.notes,
    startDate: startDate,
    project: interventation.project,
    type: interventation.type
  }
  return axios.post('/api/interventation', payload)
    .then(res => res.data)
    .then(data => _convertToInterventationView(data))
    .catch(err => console.log(err))
}

const edit = (date, interventation) => {
  const time = interventation.time
  const startDate = date !== null ? date : interventation.startDate
  if (time.length > 0) {
    const hours = parseInt(time.substring(0, 2))
    const minutes = parseInt(time.substring(3))
    startDate.setHours(hours, minutes)
  } else {
    startDate.setHours(0, 0);
  }
  const payload = {
    id: interventation.id,
    notes: interventation.notes,
    startDate: startDate,
    project: interventation.project,
    type: interventation.type
  }
  return axios.put(`/api/interventation/${interventation.id}`, payload)
    .then(res => res.data)
    .then(data => _convertToInterventationView(data))
    .catch(err => console.log(err))
}

const fetchInterventations = (fromDate, toDate) => {
  const config = {
    params: {
      from: fromDate,
      to: toDate
    }
  }
  return axios.get('/api/interventation', config)
    .then(res => res.data)
    .then(data => data.map(i => _convertToInterventationView(i)))
    .catch(err => console.log(err))
}

const remove = (interventation) => {
  return axios.delete(`/api/interventation/${interventation.id}`)
    .then(res => interventation)
    .catch(err => console.log(err))
}

const _convertToInterventationView = (interventation) => {
  const ts = Date.parse(interventation.startDate)
  const startDate = new Date()
  startDate.setTime(ts)
  const timeString = _timeString(startDate)
  const title = interventation.type === 'reminder' ? `⚠️ ${interventation.notes}` : _interventationTitle(interventation)
  const classes = []
  if (interventation.type === 'reminder') {
    classes.push('reminder')
  }
  if (interventation.project && interventation.project.fixing) {
    classes.push('fixing')
  }
  const viewObj = Object.assign(interventation, {
    title: title,
    startDate: startDate,
    time: timeString === '00:00' ? '' : timeString,
    classes: classes
  })
  return viewObj
}

const _interventationTitle = (interventation) => {
  let title = ''
  if (interventation.project.destinationCustomer) {
    title = `${interventation.project.destinationCustomer.name} - `
  } else if (interventation.project.billingCustomer) {
    title = `${interventation.project.billingCustomer.name} - `
  }
  title += `${interventation.project.code}`
  return title
}

const _timeString = (date) => {
  return date.toLocaleString('it-IT', { hour: '2-digit', minute: '2-digit' })
}
export default {
  save,
  edit,
  remove,
  fetchInterventations
}

import axios from '@/services/httpService';

const fetchActivities = () => {
  return axios.get('/api/rendicontazione/activities')
    .then(res => res.data)
    .catch(err => console.log(err));
};

const fetchUsersData = () => {
  return axios.get('/api/user/all/data')
    .then(res => res.data)
    .catch(err => console.log(err));
};

const saveRendicontazione = (rendicontazione) => {
  const payload = {
    employeeId: rendicontazione.employee.id,
    projectId: rendicontazione.project.id,
    activityId: rendicontazione.activity.id,
    date: rendicontazione.date,
    notes: rendicontazione.notes,
    hours: rendicontazione.hours,
    minutes: rendicontazione.minutes
  }
  return axios.post('/api/rendicontazione', payload)
    .then(res => res.data)
    .catch(err => console.log(err));
};

const editRendicontazione = (rendicontazione) => {
  const payload = {
    employeeId: rendicontazione.employee.id,
    projectId: rendicontazione.project.id,
    activityId: rendicontazione.activity.id,
    date: rendicontazione.date,
    notes: rendicontazione.notes,
    hours: rendicontazione.hours,
    minutes: rendicontazione.minutes
  }
  return axios.put(`/api/rendicontazione/${rendicontazione.id}`, payload)
    .then(res => res.data)
    .catch(err => console.log(err));
};

const fetchRendicontazioni = (filter = {}) => {
  const config = {
    params: {
      projectId: filter.projectId,
      date: filter.date,
      employeeId: filter.employee,
      page: 0,
      size: 100
    }
  };
  return axios.get('/api/rendicontazione', config)
    .then(res => res.data)
    .catch(err => console.log(err));
};

const deleteRendicontazione = (rendicontazioneId) => {
  return axios.delete(`/api/rendicontazione/${rendicontazioneId}`)
    .catch(err => console.log(err));
}

const exportRendicontazione = (month, year) => {
  const formattedMonth = month.toLocaleString('it-IT', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
  return axios.get(`/api/rendicontazione/export/${month}/${year}`, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = `export-rendincontazione-${formattedMonth}-${year}.xlsx`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    })
}

const statistics = () => {
  return axios.get('/api/statistics/rendicontazione')
    .then(res => res.data)
    .catch(err => { console.log(err) })
}

export default {
  fetchActivities,
  fetchUsersData,
  saveRendicontazione,
  fetchRendicontazioni,
  deleteRendicontazione,
  editRendicontazione,
  exportRendicontazione,
  statistics
}

<template>
  <b-form class="mt-5" @reset.prevent="onReset" @submit.prevent="onSubmit" novalidate>
    <b-form-group :label="`Inserisci la nuova password per l'utente ${username}`">
      <b-input type="password" v-model="newPwd"></b-input>
    </b-form-group>
    <b-button type="reset" class="mr-2">Annulla</b-button>
    <b-button type="submit">Salva</b-button>
  </b-form>
</template>

<script>
import userService from '@/services/userManagementService'

export default {
  created() {
    const userId = this.$route.params.id
    if (userId) {
      userService.fetchUser(userId)
        .then(data => {
          this.user = data
          this.username = data.username
        })
    }
  },
  data() {
    return {
      newPwd: '',
      username: '',
      user: null
    }
  },
  methods: {
    onReset() {
      this.$router.push(`/gestione-utenti/utente/${this.$route.params.id}`)
    },
    onSubmit() {
      userService.changePassword(this.user, this.newPwd)
        .then(data => {
          this.$store.dispatch('setMsg', 'Password cambiata')
          this.$router.push(`/gestione-utenti/utente/${this.user.id}`)
        })
    }
  }
}
</script>

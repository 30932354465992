<template>
  <div>
    <b-alert v-model="showAuthError" variant="danger" dismissible>
      Autenticazione errata
    </b-alert>
    <b-form @submit.prevent="onSubmit">
      <b-form-group id="input-group-1" label="Utente:" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="form.nickname"
          type="text"
          placeholder="Inserisci utente"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Password:" label-for="input-2">
        <b-form-input
          id="input-2"
          type="password"
          v-model="form.password"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit">Accedi</b-button>
    </b-form>
  </div>
</template>

<script>
import userService from '../services/userService';

export default {
  created() {
    this.$store.dispatch('cleanError')
  },
  data() {
    return {
      form: {},
      showAuthError: false
    };
  },
  methods: {
    onSubmit() {
      userService.authenticate(this.form)
        .then(authenticationOk => {
          if (!authenticationOk) {
            this.$store.dispatch('cleanError')
            this.showAuthError = true;
          } else {
            this.$router.push('/');
          }
        });
    }
  }
};
</script>

<template>
  <div>
    <h5 v-show="rendicontazioni.length > 0">Rendicontazione totale: {{total}}</h5>
    <b-list-group>
      <b-list-group-item v-for="(r, index) in rendicontazioni" :key="r.id" @click="loadRendicontazione(index, r.id)">
        <div class="d-flex w-100 justify-content-between">
          <small>{{r.activity.name}}</small>
          <small>{{r.date | fdate}}</small>
        </div>
        <div class="d-flex w-100 justify-content-between">
          <small>{{r.project ? `${r.project.code} - ${r.project.name}` : ''}}</small>
          <small>{{r.hours}}h {{r.minutes}}m</small>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default ({
  created() {},
  data() {
    return {
      dateFilter: null
    }
  },
  computed: {
    rendicontazioni() {
      return this.$store.getters.rendicontazioni;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    total() {
      const hours = this.rendicontazioni.reduce((accum, r) => { return accum + r.hours }, 0);
      const minutes = this.rendicontazioni.reduce((accum, r) => { return accum + r.minutes }, 0);
      const hoursToSum = Math.floor(minutes / 60);
      const minutesRemainder = minutes % 60;
      return `${hours + hoursToSum}h ${minutesRemainder}m`;
    }
  },
  methods: {
    loadRendicontazione(index, id) {
      this.$store.dispatch('loadRendicontazione', { index, id })
      this.$emit('edit');
    }
  }
})
</script>

<template>
    <div>
      <b-row class="mt-5" v-if="isEditAction">
        <b-col>
          <b-button :to="`/gestione-utenti/utente/${this.$route.params.id}/cambia-password`" class="mt-3 mb-3">Cambia password</b-button>
      </b-col>
      </b-row>
      <b-alert
        v-if="showMsgs"
        variant="success"
        fade
        :show="2"
        @dismissed="cleanMsgs"
      >
        {{msg}}
      </b-alert>
      <b-alert :show="showErrors" variant="danger">
        {{errorMsg}}
      </b-alert>
      <b-alert
        :show="isEditAction"
        variant="warning"
      >
      ⚠️ Attenzione: se uno fra i campi "Username", "Password" o "Ruolo di sistema" viene modificato per un utente, il sistema forzerà
      la richiesta di un nuovo login per l'utente al fine di rendere effettive le modifiche apportate.
      </b-alert>
      <b-form @submit.prevent="onSubmit" @reset.prevent="onReset" novalidate>
        <b-form-group
          label="Username"
        >
          <b-input
            id="username"
            v-model.trim="user.username"
          >
          </b-input>
        </b-form-group>
        <b-form-group
          label="Password"
          v-if="!isEditAction"
        >
          <b-input
            id="password"
            type="password"
            v-model.trim="user.password"
          >
          </b-input>
        </b-form-group>
        <b-form-group
          label="Nome"
        >
        <b-input
            id="nome"
            v-model.trim="user.name"
        >
        </b-input>
        </b-form-group>
        <b-form-group
          label="Cognome"
        >
        <b-input
            id="cognome"
            v-model.trim="user.surname"
        >
        </b-input>
        </b-form-group>
        <b-form-group label="Ruolo di sistema">
            <b-select
              :options="roleOptions"
              text-field="name"
              value-field="id"
              v-model="user.role"
            >
              <template #first>
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
            </b-select>
          </b-form-group>
        <b-form-group
          label="Capofficina"
          v-if="isTecnicoSelected"
        >
          <b-form-checkbox
              id="capofficina"
              v-model="user.isCapofficina"
              name="capofficina"
              value="true"
              :unchecked-value="false"
          >
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          label="Attivo"
        >
        <b-form-checkbox
            id="enabled"
            v-model="user.enabled"
            name="enabled"
            value="true"
            unchecked-value="false"
        >
        </b-form-checkbox>
        </b-form-group>
        <b-form-group
          label="Mostra in rendicontazione"
        >
        <b-form-checkbox
            id="export-in-rendicontazione"
            v-model="user.useInExportRendicontazione"
            name="export-in-rendicontazione"
            value="true"
            unchecked-value="false"
        >
        </b-form-checkbox>
        </b-form-group>
        <b-form-group
          label="Ruolo di lavoro"
        >
        <b-input
            id="work-role"
            v-model.trim="user.workRole"
        >
        </b-input>
        </b-form-group>
        <b-form-group
          label="Ore lavorate a settimana"
        >
        <b-input
            id="weekly-working-hours"
            type="number"
            min="0"
            v-model.number="user.weeklyWorkingHours"
        >
        </b-input>
        </b-form-group>
          <div>
          <b-button type="reset" class="mr-2">Annulla</b-button>
          <b-button type="submit">Salva</b-button>
          </div>
      </b-form>
    </div>
</template>

<script>
import userService from '@/services/userManagementService'
import User from '@/models/User'
import { mapGetters } from 'vuex';

export default {
  created() {
    userService.fetchRoles().then((roles) => {
      this.roleOptions = roles.map(r => ({ id: r.authority, name: r.authority.substring(5) })).filter(option => option.id !== 'ROLE_CAPOFFICINA');
    });
    const userId = this.$route.params.id
    if (userId) {
      userService.fetchUser(userId)
        .then(data => {
          this.user = data
        })
    }
  },
  data() {
    return {
      user: new User(),
      roleOptions: [],
      errorMsg: ''
    }
  },
  computed: {
    showMsgs() {
      return this.msg.length > 0
    },
    showErrors() {
      return this.errorMsg.length > 0
    },
    isEditAction() {
      return this.$route.params.id !== undefined
    },
    ...mapGetters([
      'msg'
    ]),
    isTecnicoSelected() {
      return this.user.role === 'ROLE_TECNICO'
    }
  },
  methods: {
    validate(user) {
      if (!user.username || user.username.length === 0) {
        this.errorMsg = 'Username obbligatorio'
        return false
      }
      if (user.role === null) {
        this.errorMsg = 'Ruolo obbligatorio'
        return false
      }
      if (user.role !== 'ROLE_ADMIN' && (!user.weeklyWorkingHours || typeof user.weeklyWorkingHours !== 'number' || user.weeklyWorkingHours < 0)) {
        this.errorMsg = 'Ore lavorate a settimana deve contenere un numero maggiore o uguale a 0'
        return false
      }
      return true
    },
    onSubmit() {
      const formValidated = this.validate(this.user)
      if (!formValidated) {
        return
      }
      if (this.isEditAction) {
        userService.edit(this.user)
          .then(data => {
            if (data.status && data.status === 400) {
              this.errorMsg = 'Questo username è già utilizzato'
            } else {
              this.$store.dispatch('setMsg', 'Utente modificato')
              this.$router.push('/gestione-utenti')
            }
          })
      } else {
        userService.save(this.user)
          .then(data => {
            if (data.status && data.status === 400) {
              this.errorMsg = 'Questo username è già utilizzato'
            } else {
              this.$store.dispatch('setMsg', 'Utente creato')
              this.$router.push('/gestione-utenti')
            }
          })
      }
    },
    onReset() {
      this.errorMsg = ''
      this.$router.push('/gestione-utenti')
    },
    cleanMsgs() {
      this.$store.dispatch('setMsg', '')
    }
  }
}
</script>

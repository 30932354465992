<template>
  <div>
    <agenda-header @date="fetch"/>
    <b-list-group>
      <b-list-group-item v-for="r in interventions" :key="r.id">
        <div v-if="r.type === 'interventation'">
          <div>
            <small><b-link :to="'/progetto/'+r.project.id">{{r.project.code +' - ' + r.project.name}}</b-link></small>
            <b-icon v-show="r.project.fixing" icon="tools" class="ml-1" title="riparazione"></b-icon>
          </div>
          <div>
            <b-icon icon="alarm"></b-icon>
            <small class="ml-1"><strong>{{r.time}}</strong></small>
          </div>
          <div>
            <b-icon icon="card-text"></b-icon>
            <small class="ml-1 text-block">{{r.notes}}</small>
          </div>
          <span v-show="r.project.ladderTruck" class="tag">autoscala</span>
        </div>
        <div v-else>
          <div>
             ⚠️<small class="ml-1 text-block">{{r.notes}}</small>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import AgendaHeader from '@/components/intervention/AgendaHeader'
import interventionService from '@/services/interventationService'

export default {
  data() {
    return {
      interventions: []
    }
  },
  methods: {
    fetch(d) {
      const endOfTheDay = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59)
      const sortCriteria = (f, s) => f.startDate <= s.startDate ? -1 : 1
      interventionService.fetchInterventations(d, endOfTheDay)
        .then(data => { this.interventions = data.sort(sortCriteria) })
    }

  },
  components: {
    AgendaHeader
  }
}
</script>

<style>
.tag {
  background-color: #1de6c9;
  border-radius: 5px;
  padding: 4px;
  font-size: 13px;
}

.text-block {
  white-space: pre-line;
}
</style>

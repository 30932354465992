<template>
  <div>
  <b-table
    ref="projectsTable"
    responsive
    striped
    :fields="fields"
    :items="dataProvider"
    :per-page="perPage"
    :current-page="currentPage"
  >
    <template #cell(code)="row">
      <shared-folder-link :project="row.item"/>
    </template>
     <template #cell(name)="row">
      <b-link :to="`/progetto/${row.item.id}`" :title="row.item.name ? row.item.name : 'Progetto senza nome'">
        <span class="d-inline-block text-truncate" style="max-width: 500px;">
          {{row.item.name ? row.item.name : 'Progetto senza nome'}}
        </span>
      </b-link>
    </template>
    <template #cell(description)="row">
      <span class="d-inline-block text-truncate" style="max-width: 500px;">
          {{ row.item.description }}
      </span>
    </template>
   <template #cell(actions)="row">
     <div v-if="!isTecnico">
        <b-button @click="modelSelection(row.item)" size="sm" title="Stampa scheda" class="mr-2">
           <b-icon icon="printer"></b-icon>
        </b-button>
         <b-button @click="goToRendicontazione(row.item)" size="sm" title="Vai a rendicontazione" v-if="isAdmin">
           <b-icon icon="arrow-right"></b-icon>
        </b-button>
     </div>
    </template>
  </b-table>
  <b-pagination
    v-model="currentPage"
    :total-rows="rows"
    :per-page="perPage"
  ></b-pagination>
  <b-modal
    id="model-selection"
    :hide-footer="true"
  >
    <b-form-group
      label="Scegli il modello"
    >
      <b-select
        :options="models"
        v-model="model"
      />
    </b-form-group>
    <b-button @click="generate" :disabled="!enableGeneration">Genera</b-button>
  </b-modal>
  </div>
</template>

<script>
import projectService from '@/services/projectService'
import SharedFolderLink from '@/components/project/SharedFolderLink'

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      rows: 0,
      fields: [
        {
          key: 'code',
          label: 'Codice',
          tdClass: 'bgByState'
        },
        {
          key: 'name',
          label: 'Nome'
        },
        {
          key: 'location',
          label: 'Luogo'
        },
        {
          key: 'description',
          label: 'Descrizione'
        },
        {
          key: 'employee',
          label: 'Dipendente',
          formatter: (value) => value.name
        },
        {
          key: 'state',
          label: 'Stato',
          formatter: (value) => value ? value.name : ''
        },
        {
          key: 'actions',
          label: 'Azioni'
        }
      ],
      projects: [],
      project: {},
      models: [],
      model: null
    }
  },
  computed: {
    filter() {
      return this.$store.getters.filter
    },
    enableGeneration() {
      return this.model !== null
    },
    isTecnico() {
      return this.$store.getters.isTecnico
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    }
  },
  watch: {
    filter: {
      handler: function() {
        this.$refs.projectsTable.refresh();
      }
    }
  },
  methods: {
    dataProvider(ctx) {
      return projectService.fetchProjects(ctx, this.filter)
        .then(page => {
          this.rows = page.totalElements;
          return page.content;
        });
    },
    bgByState(value, key, item) {
      if (item.state) {
        switch (item.state.id) {
          case 1:
            return 'bg-rilievo';
          case 2:
            return 'bg-disegno';
          case 3:
            return 'bg-preventivo';
          case 4:
            return 'bg-attesa-cliente';
          case 5:
            return 'bg-domande-comunali';
          case 6:
            return 'bg-realizzazione';
          case 7:
            return 'bg-completato';
          case 8:
            return 'bg-non-accettato';
          case 9:
            return 'bg-realizzazione'
          default:
            return ''
        }
      } else {
        return ''
      }
    },
    modelSelection(project) {
      this.project = project
      this.model = null
      // selection of available models for the project
      this.models = projectService.fetchModels(project.fixing).map(m => ({ text: m.name, value: m }))
      this.$bvModal.show('model-selection')
    },
    generate() {
      projectService.generateDoc(this.project, this.model)
        .then(data => { this.$refs.projectsTable.refresh() })
      this.$bvModal.hide('model-selection')
    },
    goToRendicontazione(project) {
      this.$store.dispatch('setRendicontazioneFilter', { projectId: project.id })
      this.$router.push('/rendicontazione')
    }
  },
  components: {
    SharedFolderLink
  }
}
</script>

<style>
.bg-rilievo {
  background-color: rgb(255, 0, 0)
}
.bg-disegno {
  background-color: rgb(255, 0, 0)
}
.bg-preventivo {
  background-color: rgb(255, 0, 0)
}
.bg-attesa-cliente {
  background-color: rgb(255, 165, 0)
}
.bg-domande-comunali {
  background-color: rgb(238, 130, 238);
}.bg-realizzazione {
  background-color: rgb(144, 238, 144);
}.bg-completato {
  background-color: rgb(0, 128, 0);
}.bg-non-accettato {
  background-color: rgb(168, 175, 175);
}

.code {
  color: black;
  font-weight: bold;
}
.code:hover {
  color: black
}
</style>

<template>
    <b-container fluid>
      <Navigation v-if="isAuthenticated"/>
      <error-dialog />
      <router-view/>
    </b-container>
</template>

<script>
import Navigation from '@/components/Navigation'
import ErrorDialog from '@/components/ErrorDialog'
import { mapGetters } from 'vuex'

export default {
  components: {
    Navigation,
    ErrorDialog
  },
  computed: {
    ...mapGetters([
      'isAuthenticated'
    ])
  }
}
</script>

<style>
.form-control:disabled, input:disabled {
  background-color: #d6d7d9 !important;
}
</style>

<template>
  <b-row class="mt-2">
    <b-col md="6">
      <b-form-group label="Cerca" label-cols-sm="1" label-align-sm="left">
        <b-form-input
          class="mr-2"
          v-model="filter.value"
          @input="refreshFilter"
        ></b-form-input>
      </b-form-group>
       <b-row>
        <b-col md="6">
       <b-form-group label="Stato" label-cols-sm="2" label-align-sm="left">
            <b-form-select
              :options="stateFilterOptions"
              v-model="filter.state"
              text-field="name"
              value-field="id"
              @change="refreshFilter"
            >
              <!-- <template #first>
                <b-form-select-option :value="null">Mostra tutti</b-form-select-option>
              </template> -->
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group label="Ruolo" label-cols-sm="2" label-align-sm="left">
            <b-form-select
              :options="roleOptions"
              v-model="filter.role"
              text-field="name"
              value-field="id"
              @change="refreshFilter"
            >
              <template #first>
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button @click="clear">Pulisci</b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import userManagementService from '@/services/userManagementService';
import UserManagementFilter from '@/models/UserManagementFilter';

export default {
  created() {
    userManagementService.fetchRoles().then((roles) => {
      this.roleOptions = roles.map(r => ({ id: r.authority, name: r.authority.substring(5) }));
    });
    // read filter from vuex
    this.filter = this.getFilter;
  },
  data() {
    return {
      filter: new UserManagementFilter(),
      roleOptions: [],
      stateFilterOptions: [
        { id: 'SHOW_ALL', name: 'Mostra tutti' },
        { id: 'SHOW_ENABLED', name: 'Mostra attivi' },
        { id: 'SHOW_DISABLED', name: 'Mostra disattivi' },
        { id: 'SHOW_ENABLED_IN_RENDICONTAZIONE', name: 'Visibili in rendicontazione' },
        { id: 'SHOW_DISABLED_IN_RENDICONTAZIONE', name: 'Non visibili in rendicontazione' }
      ]

    };
  },
  computed: {
    ...mapGetters({
      getFilter: 'userManagementFilter'
    })
  },
  methods: {
    refreshFilter() {
      this.setUserManagementFilter(Object.assign({}, this.filter));
    },
    clear() {
      this.filter = new UserManagementFilter();
      this.refreshFilter();
    },
    ...mapActions(['setUserManagementFilter'])
  }
};
</script>

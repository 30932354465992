<template>
<div>
  <b-table striped :fields="fields" :items="rendicontazioni" :foot-clone="tableFooter">
    <template #cell(projectCode)="row">
      {{row.item.project ? row.item.project.code : ''}}
    </template>
    <template #cell(timeSpent)="data">
      {{data.item.hours}}h {{data.item.minutes}}m
    </template>
    <template #cell(actions)="row">
       <b-button size="sm" @click="loadRendicontazione(row.index, row.item.id)" title="Modifica" class="mr-2">
           <b-icon icon="brush"></b-icon>
        </b-button>
        <b-button size="sm" @click="showDeleteConfirmation(row.index, row.item.id)" title="Elimina" variant="danger">
           <b-icon icon="trash"></b-icon>
        </b-button>
    </template>
    <template #foot(timeSpent)="">
       {{total}}
    </template>
    <template #foot()="">
      {{""}}
    </template>
  </b-table>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      fields: [
        {
          key: 'projectCode',
          label: 'Codice'
        },
        {
          key: 'project',
          label: 'Progetto',
          formatter: (value) => {
            return value ? value.name : '';
          }
        },
        {
          key: 'date',
          label: 'Data',
          formatter: (value) => {
            const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
            return new Date(value).toLocaleString('it-IT', options);
          }
        },
        {
          key: 'employee',
          label: 'Dipendente',
          formatter: (value) => {
            return value.name;
          }
        },
        {
          key: 'activity',
          label: 'Attività',
          formatter: (value) => {
            return value.name;
          }
        },
        {
          key: 'timeSpent',
          label: 'Ore di lavoro'
        },
        {
          key: 'actions',
          label: 'Azioni'
        }
      ]
    };
  },
  computed: {
    total() {
      const hours = this.rendicontazioni.reduce((accum, r) => { return accum + r.hours }, 0);
      const minutes = this.rendicontazioni.reduce((accum, r) => { return accum + r.minutes }, 0);
      const hoursToSum = Math.floor(minutes / 60);
      const minutesRemainder = minutes % 60;
      return `${hours + hoursToSum}h ${minutesRemainder}m`;
    },
    tableFooter() {
      return (this.projectFilterEnabled || this.employeeFilterEnabled || !this.isAdmin) && this.rendicontazioni.length > 0
    },
    employeeFilterEnabled() {
      return this.rendicontazioneFilter.date !== null && this.rendicontazioneFilter.employee !== null
    },
    projectFilterEnabled() {
      return this.rendicontazioneFilter.projectId !== null
    },
    ...mapGetters([
      'isAdmin',
      'rendicontazioni',
      'rendicontazioneFilter'
    ])
  },
  methods: {
    loadRendicontazione(index, id) {
      this.$store.dispatch('loadRendicontazione', { index, id })
      this.$emit('edit');
    },
    showDeleteConfirmation(index, id) {
      this.$store.dispatch('loadRendicontazione', { index, id })
      this.$bvModal.show('delete-confirm');
    }
  }
}
</script>

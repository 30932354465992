<template>
  <div class="project-detail">
    <h6 class="header">{{header}}</h6>
    <b-button-group>
      <b-button :href="`tel://+${mobileForWhatsAppUrl}`" :disabled="disableCall" size="sm" title="chiama">
        <b-icon icon="telephone-fill"></b-icon>
      </b-button>
      <b-button :href="`mailto:${iContact.email}`" :disabled="disableEmail" size="sm" title="manda email">
        <b-icon icon="wallet-fill"></b-icon>
      </b-button>
      <b-button :href="`https://wa.me/${mobileForWhatsAppUrl}`" :disabled="disableWhatsApp" size="sm" title="chat su WhatsApp" target="_blank">
        <b-icon icon="chat"></b-icon>
      </b-button>
    </b-button-group>
    <b-row cols-md="4" cols="1">
      <b-col>
        <b-form-group label="Nome">
          <b-input v-model.trim="iContact.name" :disabled="disabled" @input="updateContact"/>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Telefono">
          <b-input v-model.trim="iContact.telephone" :disabled="disabled" @input="updateContact"/>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Cellulare">
          <b-input v-model.trim="iContact.mobile" :disabled="disabled" @input="updateContact"/>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Email">
          <b-input v-model.trim="iContact.email" :disabled="disabled" @input="updateContact"/>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CustomerContact from '@/models/CustomerContact'

export default {
  props: {
    contact: Object,
    disabled: Boolean,
    header: String
  },
  data() {
    return {
      iContact: new CustomerContact()
    }
  },
  computed: {
    disableCall() {
      return this.iContact.mobile === undefined || this.iContact.mobile === null || this.iContact.mobile.length === 0
    },
    disableEmail() {
      return this.iContact.email === undefined || this.iContact.email === null || this.iContact.email.length === 0
    },
    disableWhatsApp() {
      return this.iContact.mobile === undefined || this.iContact.mobile === null || this.iContact.mobile.length === 0
    },
    mobileForWhatsAppUrl() {
      if (this.iContact.mobile) {
        let mobile = this.iContact.mobile
        mobile = mobile.split('').map(c => parseInt(c)).filter(d => Number.isInteger(d)).join('')
        if (mobile.length < 12) {
          mobile = '39' + mobile
        }
        return mobile
      } else {
        return null
      }
    }
  },
  methods: {
    updateContact(v) {
      this.$emit('updateContact', this.iContact)
    }
  },
  watch: {
    contact: {
      handler: function(value) {
        if (value) {
          this.iContact = value
        } else {
          this.iContact = new CustomerContact()
        }
      }
    }
  }
}
</script>

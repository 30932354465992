import axios from '@/services/httpService';
import User from '@/models/User'

const fetchUsers = (ctx = { currentPage: 1, perPage: 5 }, filter) => {
  const config = {
    params: {
      page: ctx.currentPage - 1,
      size: ctx.perPage,
      value: filter.value,
      role: filter.role,
      state: filter.state
    }
  }

  return axios.get('/api/user/paged', config)
    .then(res => res.data)
    .catch(err => console.log(err))
};

const fetchUser = (id) => {
  return axios.get(`/api/user/${id}`)
    .then(res => {
      const u = new User();
      u.id = res.data.id
      u.username = res.data.username
      u.name = res.data.name
      u.surname = res.data.surname
      u.role = res.data.authorities.filter(a => a.authority !== 'ROLE_CAPOFFICINA')[0].authority
      u.isCapofficina = res.data.authorities.find(a => a.authority === 'ROLE_CAPOFFICINA') !== undefined
      u.enabled = res.data.enabled
      u.workRole = res.data.workRole
      u.useInExportRendicontazione = res.data.useInExportRendicontazione
      u.weeklyWorkingHours = res.data.weeklyWorkingHours
      return u;
    })
    .catch(err => console.log(err))
};

const fetchRoles = () => {
  return axios.get('/api/authority')
    .then(res => res.data)
    .catch(err => console.log(err))
};

const edit = (user) => {
  const userId = user.id;
  const roles = [user.role]
  if (user.isCapofficina && user.role === 'ROLE_TECNICO') {
    roles.push('ROLE_CAPOFFICINA')
  }
  const data = {
    username: user.username,
    name: user.name,
    surname: user.surname,
    roles: roles,
    enabled: user.enabled,
    useInExportRendicontazione: user.useInExportRendicontazione,
    workRole: user.workRole,
    weeklyWorkingHours: user.weeklyWorkingHours
  };
  return axios.put(`/api/user/${userId}`, data)
    .then(res => res.data)
    .catch(err => {
      if (err.response && err.response.status === 400) {
        return err.response
      } else {
        console.log(err)
      }
    })
}

const save = (user) => {
  const roles = [user.role]
  if (user.isCapofficina && user.role === 'ROLE_TECNICO') {
    roles.push('ROLE_CAPOFFICINA')
  }
  const data = {
    username: user.username,
    password: user.password,
    name: user.name,
    surname: user.surname,
    roles: roles,
    enabled: user.enabled,
    useInExportRendicontazione: user.useInExportRendicontazione,
    workRole: user.workRole,
    weeklyWorkingHours: user.weeklyWorkingHours
  };
  return axios.post('/api/user', data)
    .then(res => res.data)
    .catch(err => {
      if (err.response && err.response.status === 400) {
        return err.response
      } else {
        console.log(err)
      }
    })
}

const changePassword = (user, newPwd) => {
  const userId = user.id;
  const data = {
    password: newPwd
  };
  return axios.put(`/api/user/${userId}/change-password`, data)
    .then(res => res.data)
    .catch(err => {
      if (err.response && err.response.status === 400) {
        return err.response
      } else {
        console.log(err)
      }
    })
}

export default {
  fetchUsers,
  fetchUser,
  fetchRoles,
  edit,
  save,
  changePassword
}

<template>
     <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
        <b-form-group label="Data">
         <b-form-datepicker
           id="i-data1"
           v-model="rendicontazione.date"
           placeholder="Scegli un giorno"
           :state="isDateStateValid"
           locale="it-IT"
        >
        </b-form-datepicker>
        </b-form-group>
        <ProjectNameSearch  :project.sync="rendicontazione.project" :is-normal-user="!isAdmin" />
        <b-form-group label="Dipendente" v-show="isAdmin">
         <b-form-select
           id="i-dipendente"
           :options="employees"
           text-field="name"
           value-field="id"
           v-model="rendicontazione.employee.id"
           :state="isEmployeeStateValid"
         ></b-form-select>
        </b-form-group>
        <b-form-group label="Attività">
         <b-form-select
           id="i-attivita"
           :options="activities"
           text-field="name"
           value-field="id"
           v-model="rendicontazione.activity.id"
           :state="isActivityStateValid"
           @change="checkNoteField"
         ></b-form-select>
        </b-form-group>
        <b-form-group label="Descrizione" v-show="showNoteField">
          <b-textarea
            id="i-note"
            class="mt-2"
            v-model="rendicontazione.notes"
            ></b-textarea>
        </b-form-group>
        <b-form-row>
          <b-col sm="6">
            <b-form-group label="Ore">
              <b-form-select
                id="i-ore"
                :options="hours"
                v-model="rendicontazione.hours"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Minuti">
              <b-form-select
                id="i-minuti"
                :options="minutes"
                v-model="rendicontazione.minutes"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>
        <div>
        <b-button type="reset" class="mr-2">Annulla</b-button>
        <b-button type="submit" :disabled="invalid">Salva</b-button>
        <b-button @click="showDeleteConfirmation" title="Elimina" variant="danger" class="d-md-none d-lg-none d-xl-none d-sm-block float-right" v-show="isEditAction">
           Elimina
        </b-button>
        </div>
    </b-form>
</template>

<script>
import rendicontazioneService from '@/services/rendicontazioneService';
import ProjectNameSearch from '@/components/project/ProjectNameSearch'
import { mapGetters } from 'vuex';
import { projectStates } from '@/services/projectService'

export default {
  created() {
    if (!this.isAdmin) {
      this.rendicontazione.employee.id = this.profile.id
    }
    if (this.rendicontazioneFilter.date !== undefined && this.rendicontazioneFilter.date !== null) {
      this.rendicontazione.date = this.rendicontazioneFilter.date
    }
    rendicontazioneService.fetchActivities()
      .then(data => { this.activities = data });
    rendicontazioneService.fetchUsersData()
      .then(data => { this.employees = data });
    if (!this.isEditAction) {
      if (this.rendicontazioneFilter.projectId !== undefined && this.rendicontazioneFilter.projectId !== null) {
        this.rendicontazione.project.id = this.rendicontazioneFilter.projectId
      } else {
        this.rendicontazione.project.id = null
      }
    }
  },
  data() {
    return {
      inLavorazioneState: projectStates.inLavorazione,
      employees:
        [],
      activities:
        [],
      hours: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
      ],
      minutes: [
        0, 15, 30, 45
      ]
    };
  },
  computed: {
    showNoteField() {
      return this.rendicontazione.activity.id === projectStates.varie
    },
    rendicontazione() {
      return this.$store.getters.loadedRendicontazione.content;
    },
    isEditAction() {
      return this.$store.getters.loadedRendicontazione.state === 'edit';
    },
    loadedRendicontazione() {
      return this.$store.getters.loadedRendicontazione;
    },
    isDateStateValid() {
      return this.rendicontazione.date !== undefined && this.rendicontazione.date !== null;
    },
    isEmployeeStateValid() {
      return this.rendicontazione.employee.id !== undefined && this.rendicontazione.employee.id !== null;
    },
    isActivityStateValid() {
      return this.rendicontazione.activity.id !== undefined && this.rendicontazione.activity.id !== null;
    },
    isTimeSpentStateValid() {
      return this.rendicontazione.hours !== 0 || this.rendicontazione.minutes !== 0
    },
    invalid() {
      // WORKAROUND because isEmployeeStateValid computed is not trigger correctly when value is changed
      // programmatically. Strange thing: setting date trigger computed isEmployeeStateValid too
      const isEmployeeValid = this.rendicontazione.employee.id !== undefined && this.rendicontazione.employee.id !== null
      return !this.isDateStateValid || !this.isActivityStateValid || !isEmployeeValid || !this.isTimeSpentStateValid
    },
    ...mapGetters([
      'isAdmin',
      'profile',
      'rendicontazioneFilter'
    ])
  },
  methods: {
    onReset() {
      this.$store.dispatch('unloadRendicontazione');
      this.$emit('reset');
    },
    onSubmit() {
      if (this.isEditAction) {
        this.$store.dispatch('editRendicontazione', { index: this.loadedRendicontazione.index, content: Object.assign({}, this.rendicontazione) });
      } else {
        this.$store.dispatch('saveRendicontazione', Object.assign({}, this.rendicontazione));
      }
      this.$store.dispatch('unloadRendicontazione');
      this.$emit('submit')
    },
    checkNoteField() {
      if (this.rendicontazione.activity.id !== projectStates.varie) {
        this.rendicontazione.notes = '';
      }
    },
    showDeleteConfirmation() {
      this.$bvModal.show('delete-confirm');
    }
  },
  components: {
    ProjectNameSearch
  }
}
</script>
